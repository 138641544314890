import logo from "./logo.svg";
import "./App.css";

import {
  BrowserRouter,
  Route,
  Routes,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/Login";
import { useEffect } from "react";
import Register from "./pages/Register";
import Plans from "./pages/Plans";
import SucessPage from "./pages/SucessPage";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname == "/" || location.pathname == "") {
      navigate("/register");
    }
  }, [location]);
  return (
    <Routes>
      <Route path="/home" element={<Plans />} />
      <Route path="/register" element={<Register />} />
      <Route path="/success" element={<SucessPage />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
