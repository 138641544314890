import React from 'react'
import bgBlue from "../assets/images/bgGrey.jpg";

const Plans = () => {
  return (
    <div
      className=""
      style={{
        background: `url(${bgBlue})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
    <div>Plans</div>
    </div>
  )
}

export default Plans